.custom-switch.left {
  padding-left: 0 !important;
}

.custom-switch.left .custom-control-label {
  left: 0;
  padding-left: 0;
}

.custom-switch.left .custom-control-label::before {
  left: 10rem !important;
}

.custom-switch.left .custom-control-label::after {
  left: calc(10rem + 2px) !important;
}

.td-right {
  text-align: right;
}
