// 
// _footer.scss
// 

// .footer {
//     bottom: 0;
//     padding: 20px calc(#{$grid-gutter-width} / 2);
//     position: absolute;
//     right: 0;
//     color: $footer-color;
//     left: 250px;
//     height: $footer-height;
//     background-color: $footer-bg;
// }

.footer {
    bottom: -120px;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: absolute;
    right: 0;
    color: #74788d;
    left: 250px;
    height: 180px;
    background-color: #f2f2f5;
}

// @media (max-width: 992px) {
//     .footer {
//         bottom: -650px;
//         left: 0;
//         min-height: 316px;
//     }
// }

// em hoi update responsive
@media only screen and (max-width: 992px) and (min-width: 768px) {
    .footer {
        bottom: -120px;
        left: 0;
        min-height: 180px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 576px) {
    .footer {
        bottom: -243px;
        left: 0;
        min-height: 303px;
    }
}

@media (max-width: 575px) {
    .footer {
        bottom: -589px;
        left: 0;
        min-height: 649px;
    }
}

// Enlarge menu
.vertical-collpsed {
    .footer {
        left: $sidebar-collapsed-width;
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }
}