.btn-facebook {
    background-color: #4267B2;
}

#copy-link {
    background-color: #F8F8FB;
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
  }

  .btn-circle.btn-sm {
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 15px;

  }



  .btn-circle.btn-lg {
    width: 60px;
    height: 60px;
    /* padding: 10px 16px; */
    font-size: 24px;
    line-height: 1.33;
    border-radius: 30px;
  }

#box-wrapper {
    height:110px;
    display:block;
    overflow-y:hidden;
    overflow-x:hidden;
    white-space:nowrap;
    padding-left: 20px;
    padding-right: 20px;
}
#box-wrapper #box-item {
    list-style-type: none;
    display:inline-block;
    margin-left: 10px;
    margin-right: 10px;
}

#box {
    position: relative;
}

#scroll-left {
    position: absolute;
    z-index: 1;
    top: 17px;
}

#scroll-right {
    position: absolute;
    right: 0px;
    z-index: 1;
    top: 17px;
}