#dropdown-toggle {
  color: #495057 !important;
}

.show-ct-d:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #1f3dd0;
}

.show-ct-h:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #f46a6a;
}

.btn-show {
  margin-top: -35px;
}

.summary {
  margin-top: -35px;
}
